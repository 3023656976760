import React, { FC } from 'react';
import './ErrorBar.css';

interface ErrorBarProps {
  isError: boolean;
  errorMessage: string;
}

const ErrorBar: FC<ErrorBarProps> = ({
  isError,
  errorMessage,
}: ErrorBarProps) => {
  return (
    <div className="error-bar">
      <p
        className={
          'error-bar__text ' + (isError ? '' : 'error-bar__text--hidden')
        }
      >
        {errorMessage}
      </p>
    </div>
  );
};

export default ErrorBar;
