import React, { FC, ChangeEvent } from 'react';
import { LanguageItem } from '../../models/models';
import './Dropdown.css';

interface DropdownProps<A> {
  items: A[];
  selectedItem: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  instruction?: string;
  label?: string;
}

const Dropdown: FC<DropdownProps<LanguageItem>> = ({
  items,
  selectedItem,
  onChange,
  instruction = '',
  label = '',
}: DropdownProps<LanguageItem>) => {
  // Prefer selected item if it is not an empty string over instruction.
  const selectedValue = selectedItem !== '' ? selectedItem : instruction;

  return (
    <form className="form">
      <label htmlFor="" className="label">
        {label}
      </label>
      <select
        className="dropdown custom-select"
        value={selectedValue}
        onChange={onChange}
      >
        <option disabled hidden>
          {instruction}
        </option>
        {items.map((language, i) => (
          <option key={i} value={language.code}>
            {language.label}
          </option>
        ))}
      </select>
    </form>
  );
};

export default Dropdown;
