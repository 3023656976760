import React, { FC } from 'react';
import './Button.css';

interface ButtonProps {
  label: string;
  isActive: boolean;
  submitTranslation: () => void;
}

const Button: FC<ButtonProps> = ({
  label,
  isActive,
  submitTranslation,
}: ButtonProps) => {
  return (
    <button
      disabled={!isActive}
      className="button btn btn-block text-left"
      onClick={_ => submitTranslation()}
    >
      {label}
    </button>
  );
};

export default Button;
