import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import './App.css';
import Dropdown from '../Dropdown/Dropdown';
import TextDisplay from '../TextDisplay/TextDisplay';
import Textbox from '../Textbox/Textbox';
import Button from '../Button/Button';
import Header from '../Header/Header';
import { LanguageItem } from '../../models/models';
import { ApiError } from '../../models/api';
import { translate } from '../../api/api';
import ErrorBar from '../ErrorBar/ErrorBar';

const languageList: LanguageItem[] = [
  { code: 'am', label: 'Amharic' },
  { code: 'bg', label: 'Bulgarian' },
  { code: 'my', label: 'Burmese' },
  { code: 'en', label: 'English' },
  { code: 'gu', label: 'Gujarati' },
  { code: 'ha', label: 'Hausa' },
  { code: 'ig', label: 'Igbo' },
  { code: 'ky', label: 'Kyrgyz' },
  { code: 'mk', label: 'Macedonian' },
  { code: 'ps', label: 'Pashto' },
  { code: 'sr', label: 'Serbian' },
  { code: 'sw', label: 'Swahili' },
  { code: 'ta', label: 'Tamil' },
  { code: 'ti', label: 'Tigrinya' },
  { code: 'tr', label: 'Turkish' },
  { code: 'ur', label: 'Urdu' },
  { code: 'yo', label: 'Yoruba' },
];

const App: FC = () => {
  const [inputLanguage, setInputLanguage] = useState<string>('');
  const [outputLanguage, setOutputLanguage] = useState<string>('');
  const [inputText, setInputText] = useState<string>('');
  const [outputText, setOutputText] = useState<string>('');
  const [outputLanguages, setOutputLanguages] = useState<LanguageItem[]>(
    languageList
  );
  const [isActive, setIsActive] = useState<boolean>(inputText !== '');
  const [isError, setIsError] = useState<boolean>(false);
  const [isInputRtl, setIsInputRtl] = useState<boolean>(false);
  const [isOutputRtl, setIsOutputRtl] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    'Something went wrong, please try again.'
  );
  const RTL_LANGS = ['ps', 'ur'];

  useEffect(() => {
    setIsError(false);
  }, [inputLanguage]);

  useEffect(() => {
    setIsActive(
      inputText !== '' && inputLanguage !== '' && outputLanguage !== ''
    );
  }, [inputText, inputLanguage, outputLanguage]);

  const onInputLanguageSelected = (e: ChangeEvent<HTMLSelectElement>): void => {
    const inputLanguage: string = e.target.value || 'en';
    setInputLanguage(inputLanguage);

    setIsInputRtl(RTL_LANGS.includes(inputLanguage));

    // The models can only translate to and from English. This filter prevents the user selecting a target translation language that does not exist.
    let possibleOutputLanguages;
    if (inputLanguage === 'en') {
      possibleOutputLanguages = languageList.filter(
        language => language.code !== inputLanguage
      );
    } else {
      possibleOutputLanguages = [{ code: 'en', label: 'English' }];
      setIsOutputRtl(false);
    }

    setOutputLanguages(possibleOutputLanguages);
    setOutputLanguage(possibleOutputLanguages[0].code);
  };

  const onOutputLanguageSelected = (
    e: ChangeEvent<HTMLSelectElement>
  ): void => {
    const outputLanguage: string = e.target.value || 'en';
    setOutputLanguage(outputLanguage);
    setIsOutputRtl(RTL_LANGS.includes(outputLanguage));
  };

  const submitTranslation = () => {
    setIsActive(false);
    setIsError(false);
    setOutputText('');
    handleTranslateResponse();
  };

  const handleTranslateResponse = () => {
    translate(inputLanguage, outputLanguage, inputText)
      .then(result => {
        if (result instanceof ApiError) {
          handleError(result.message, result.message);
        } else {
          setOutputText(result.translation);
        }
        setIsActive(true);
      })
      .catch(error => {
        handleError('Something went wrong. Please try again.', error.message);
      });
  };

  const handleError = (errorMessage: string, log: string) => {
    setIsError(true);
    setErrorMessage(errorMessage);
    console.error(`Translate failed: ${log}`);
  };

  return (
    <div>
      <Header />
      <main className="container">
        <ErrorBar isError={isError} errorMessage={errorMessage} />
        <div className="row">
          <div className="main__components--margin col-lg-6">
            <Dropdown
              selectedItem={inputLanguage}
              items={languageList}
              onChange={onInputLanguageSelected}
              instruction="Select a Language"
              label="Translate"
            />
          </div>
          <div className="main__components--margin col-lg-6">
            <Dropdown
              selectedItem={outputLanguage}
              items={outputLanguages}
              onChange={onOutputLanguageSelected}
              instruction="Select a Language"
              label="Into"
            />
          </div>
        </div>
        <div className="row">
          <div className="main__components--margin col-lg-6">
            <Textbox
              text={inputText}
              placeholderText="Type or paste your text here"
              textUpdate={setInputText}
              submitTranslation={submitTranslation}
              isInputRtl={isInputRtl}
            />
          </div>
          <div className="main__components--margin col-lg-6">
            <TextDisplay
              text={outputText}
              placeholderText="Translation"
              isOutputRtl={isOutputRtl}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col">
            <Button
              submitTranslation={submitTranslation}
              isActive={isActive}
              label="Translate text"
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default App;
