import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App/App';
import { load } from 'webfontloader';

load({
  google: {
    families: ['Open Sans', 'sans-serif'],
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
