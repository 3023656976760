import React, { FC, ChangeEvent, KeyboardEvent, FocusEvent } from 'react';
import './Textbox.css';

interface TextboxProps {
  textUpdate: (newText: string) => void;
  text: string;
  placeholderText: string;
  submitTranslation: () => void;
  characterLimit?: number;
  isInputRtl: boolean;
}

const Textbox: FC<TextboxProps> = ({
  textUpdate,
  text,
  placeholderText,
  submitTranslation,
  characterLimit = 280,
  isInputRtl,
}: TextboxProps) => {
  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    textUpdate(e.target.value);

  const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      submitTranslation();
    }
  };

  const onFocus = (e: FocusEvent<HTMLTextAreaElement>) => e.target.select();

  return (
    <div className="textbox">
      <textarea
        className={`textbox__input ${
          isInputRtl ? 'text-display__input--rtl' : ''
        }`}
        value={text}
        placeholder={placeholderText}
        onChange={onChange}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        maxLength={characterLimit}
      ></textarea>
      <p className="textbox__character-count">
        {text.length}/{characterLimit}
      </p>
    </div>
  );
};

export default Textbox;
