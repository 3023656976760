import React, { FC } from 'react';
import './TextDisplay.css';

interface TextDisplayProps {
  text: string;
  placeholderText: string;
  isOutputRtl: boolean;
}

const TextDisplay: FC<TextDisplayProps> = ({
  text,
  placeholderText,
  isOutputRtl,
}: TextDisplayProps) => {
  return (
    <div className="text-display">
      <textarea
        readOnly
        className={`text-display__input ${
          isOutputRtl ? 'text-display__input--rtl' : ''
        }`}
        value={text}
        placeholder={placeholderText}
        onFocus={e => {
          e.target.select();
        }}
      ></textarea>
    </div>
  );
};

export default TextDisplay;
