import {
  ApiSuccess,
  ApiError,
  ApiResponse,
  InvalidInputError,
  ServerError,
} from '../models/api';

const translate = (
  inputLanguage: string,
  outputLanguage: string,
  text: string
): Promise<ApiResponse> => {
  const data = { text, from: inputLanguage, to: outputLanguage };
  return fetch('/translate', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then<ApiSuccess>(r => handleErrors(r))
    .catch<ApiError>((e: Error) => {
      return new ApiError(e.message);
    });
};

const handleErrors = (response: Response): Promise<ApiSuccess> => {
  const statusCode = response.status;
  if (statusCode >= 400 && statusCode < 500) {
    throw new InvalidInputError(`The input was not valid.`);
  }
  if (statusCode >= 500) {
    throw new ServerError('Something went wrong, please try again.');
  }
  return response.json();
};

export { translate };
