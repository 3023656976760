export interface ApiSuccess {
  translation: string;
}

export class ApiError {
  constructor(public message: string) {}
}

export type ApiResponse = ApiSuccess | ApiError;

class InvalidInputError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = InvalidInputError.name; // stack traces display correctly now
  }
}

class ServerError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = InvalidInputError.name; // stack traces display correctly now
  }
}

export { InvalidInputError, ServerError };
