import React, { FC } from 'react';
import logo from '../../assets/logo.png';
import './Header.css';

const Header: FC = () => {
  return (
    <header className="header row">
      <div className="col">
        <a href="https://gourmet-project.eu/">
          <img className="header__image" src={logo} alt="GoURMET Logo" />
        </a>
        <p className="header__text">Translate</p>
      </div>
    </header>
  );
};

export default Header;
